import { template as template_7e4f420d0df4411ebdf1ae1dfd983c5d } from "@ember/template-compiler";
const FKControlMenuContainer = template_7e4f420d0df4411ebdf1ae1dfd983c5d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
