import { template as template_af6a2b226c2c4985b0815f93c03e064e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_af6a2b226c2c4985b0815f93c03e064e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
