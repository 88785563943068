import { template as template_c2bf580283ad4f38aead2c20f1b20b99 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_c2bf580283ad4f38aead2c20f1b20b99(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
