import { template as template_d5fc4b8ed35d4d5aa8f237f13b0fabd5 } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class FKControlCustom extends Component {
    static controlType = "custom";
    static{
        template_d5fc4b8ed35d4d5aa8f237f13b0fabd5(`
    <div class="form-kit__control-custom">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
