import { template as template_dac73fd2a3c441a59a09d438655410a6 } from "@ember/template-compiler";
const FKText = template_dac73fd2a3c441a59a09d438655410a6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
