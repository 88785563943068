import { template as template_a9bc3a065b9045d494afe9926b2519a6 } from "@ember/template-compiler";
const FKLabel = template_a9bc3a065b9045d494afe9926b2519a6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
